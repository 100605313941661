'use strict';

import perflibLoader from '@grpn/perflibloader';

let ref;

if (
  typeof Groupon !== 'undefined' && Groupon !== null
    ? (ref = Groupon.Performance) != null
      ? ref.boomerangUrl
      : void 0
    : void 0
) {
  perflibLoader(Groupon.Performance.boomerangUrl);
}
